import React, { useState, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Container, 
  Box, 
  Card, 
  CardContent, 
  Grid,
  Avatar,
  Paper,
  useTheme,
  useMediaQuery,
  Fade,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { People, Assignment, EmojiObjects, ArrowForward, KeyboardArrowUp } from '@mui/icons-material';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(8),
  marginBottom: theme.spacing(4),
  background: '#111111',
  borderRadius: '0',
  position: 'relative',
  overflow: 'hidden',
  boxShadow: 'none',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '4px',
    height: '100%',
    background: '#3B82F6',
  },
  '&:hover': {
    '& .section-icon': {
      transform: 'scale(1.1)',
    }
  }
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 80,
  height: 80,
  backgroundColor: 'transparent',
  border: '2px solid #3B82F6',
  marginBottom: theme.spacing(3),
  transition: 'all 0.3s ease-in-out',
  '& .MuiSvgIcon-root': {
    color: '#3B82F6',
    fontSize: 40
  }
}));

const TeamCard = styled(Card)(({ theme }) => ({
  height: '100%',
  borderRadius: '0',
  background: '#111111',
  border: '1px solid #333',
  transition: 'all 0.3s ease-in-out',
  overflow: 'hidden',
  position: 'relative',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: '0 20px 40px rgba(59, 130, 246, 0.1)',
    '& .member-role': {
      color: '#3B82F6'
    },
    '& .arrow-icon': {
      opacity: 1,
      transform: 'translateX(0)'
    }
  }
}));

const AnimatedTitle = styled('div')(({ theme }) => ({
  display: 'inline-block',
  cursor: 'default',
  fontSize: '2rem',
  fontWeight: 700,
  color: '#ffffff',
  '& span': {
    display: 'inline-block',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    position: 'relative',
    '&:hover': {
      transform: 'translateY(-5px) rotate(5deg)',
      color: '#3B82F6',
      textShadow: '0 0 15px rgba(59, 130, 246, 0.5)',
      animation: 'bounce 0.5s ease',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '2px',
      bottom: '-2px',
      left: 0,
      background: '#3B82F6',
      transform: 'scaleX(0)',
      transformOrigin: 'right',
      transition: 'transform 0.3s ease',
    },
    '&:hover::after': {
      transform: 'scaleX(1)',
      transformOrigin: 'left',
    },
  },
  '@keyframes bounce': {
    '0%, 100%': {
      transform: 'translateY(-5px) rotate(5deg)',
    },
    '50%': {
      transform: 'translateY(-8px) rotate(-2deg)',
    },
  },
}));

const SlidingTitle = styled(Box)(({ theme, scrolled }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  zIndex: 1100,
  padding: '20px',
  background: 'rgba(0, 0, 0, 1)', // Solid black background
  '& .title-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  '& .title-text': {
    background: 'linear-gradient(45deg, #3B82F6, #60A5FA)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    fontWeight: 700,
    fontSize: scrolled ? '2rem' : '4rem',
    transition: 'font-size 0.3s ease',
    letterSpacing: '-0.02em',
  }
}));

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Fade in={isVisible}>
      <div className={`scroll-to-top${isVisible ? ' visible' : ''}`} onClick={scrollToTop}>
        <KeyboardArrowUp />
      </div>
    </Fade>
  );
};

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(true);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const team = [
    { name: "Member 1", role: "Role TBD" },
    { name: "Member 2", role: "Role TBD" },
    { name: "Member 3", role: "Role TBD" },
    { name: "Member 4", role: "Role TBD" },
    { name: "Member 5", role: "Role TBD" },
    { name: "Member 6", role: "Role TBD" },
  ];

  const titleText = "Application";

  return (
    <Box sx={{ 
      minHeight: '100vh',
      background: '#000000',
      position: 'relative',
      paddingTop: '80px'
    }}>
      <SlidingTitle scrolled={scrolled}>
        <div className="title-container">
          <Typography 
            className="title-text"
            variant="h1" 
            sx={{ 
              fontSize: isMobile ? (scrolled ? '1.5rem' : '2.5rem') : (scrolled ? '2rem' : '4rem'),
            }}
          >
            {titleText}
          </Typography>
        </div>
      </SlidingTitle>

      <Container 
        maxWidth="lg" 
        sx={{ 
          pt: 8,
          pb: 8,
          position: 'relative',
          zIndex: 1
        }}
      >
        <Fade in={!loading} timeout={800}>
          <Box>
            <Box sx={{ mb: 8, textAlign: 'center' }}>
              <Typography variant="h2" sx={{ 
                fontWeight: 700, 
                color: '#ffffff',
                mb: 3,
                fontSize: isMobile ? '2.5rem' : '3.5rem'
              }}>
                We Build
                <Typography component="span" sx={{ 
                  color: '#3B82F6',
                  display: 'block',
                  fontSize: 'inherit'
                }}>
                  Something Amazing
                </Typography>
              </Typography>
            </Box>

            {/* Goals Section */}
            <StyledPaper>
              <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="flex-start">
                <StyledAvatar className="section-icon">
                  <EmojiObjects />
                </StyledAvatar>
                <Box ml={isMobile ? 0 : 4} mt={isMobile ? 3 : 0}>
                  <Typography variant="h3" sx={{ 
                    mb: 2, 
                    fontWeight: 700,
                    color: '#ffffff',
                    fontSize: isMobile ? '2rem' : '2.5rem'
                  }}>
                    Goals
                  </Typography>
                  <Typography variant="h6" sx={{ color: '#999', lineHeight: 1.6 }}>
                    Don't know what we are doing... but we're doing it with style! 🚀
                  </Typography>
                </Box>
              </Box>
            </StyledPaper>

            {/* Team Section */}
            <StyledPaper>
              <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="flex-start" mb={6}>
                <StyledAvatar className="section-icon">
                  <People />
                </StyledAvatar>
                <Box ml={isMobile ? 0 : 4} mt={isMobile ? 3 : 0}>
                  <Typography variant="h3" sx={{ 
                    fontWeight: 700,
                    color: '#ffffff',
                    fontSize: isMobile ? '2rem' : '2.5rem'
                  }}>
                    Our Team
                  </Typography>
                </Box>
              </Box>
              <Grid container spacing={4}>
                {team.map((member, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <TeamCard>
                      <CardContent sx={{ p: 4 }}>
                        <Typography variant="h5" gutterBottom sx={{ 
                          fontWeight: 600,
                          color: '#ffffff',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}>
                          {member.name}
                          <ArrowForward className="arrow-icon" sx={{
                            fontSize: 20,
                            opacity: 0,
                            transform: 'translateX(-20px)',
                            transition: 'all 0.3s ease-in-out',
                            color: '#3B82F6'
                          }} />
                        </Typography>
                        <Typography className="member-role" variant="body1" sx={{ 
                          color: '#999',
                          transition: 'color 0.3s ease-in-out'
                        }}>
                          {member.role}
                        </Typography>
                      </CardContent>
                    </TeamCard>
                  </Grid>
                ))}
              </Grid>
            </StyledPaper>

            {/* Projects Section */}
            <StyledPaper>
              <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="flex-start">
                <StyledAvatar className="section-icon">
                  <Assignment />
                </StyledAvatar>
                <Box ml={isMobile ? 0 : 4} mt={isMobile ? 3 : 0}>
                  <Typography variant="h3" sx={{ 
                    mb: 2, 
                    fontWeight: 700,
                    color: '#ffffff',
                    fontSize: isMobile ? '2rem' : '2.5rem'
                  }}>
                    Projects
                  </Typography>
                  <Typography variant="h6" sx={{ color: '#999', lineHeight: 1.6 }}>
                    Working on project, will update once it's done... Stay tuned! 🎯
                  </Typography>
                </Box>
              </Box>
            </StyledPaper>
          </Box>
        </Fade>
        
        {loading && (
          <Box sx={{ mt: 4 }}>
            {[1, 2, 3].map((item) => (
              <Paper
                key={item}
                className="loading"
                sx={{
                  height: '200px',
                  mb: 3,
                  background: 'var(--background-card)',
                }}
              />
            ))}
          </Box>
        )}
      </Container>
      
      <ScrollToTop />
    </Box>
  );
}

export default App;
